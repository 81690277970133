<template>
  <section class="invoice">
    <div class="container-fluid">
      <!-- Head invoice -->
      <div class="head_invoice">
        <div class="book_status">
          <h6>
            booking status :
            <span>{{ pills?.status === 1 ? "pending" : "confirmed" }}</span>
          </h6>
          <div class="chart"></div>
          <div class="chart"></div>
        </div>
        <div class="logo">
          <img :src="pills.organization?.default_image" />
        </div>
        <div class="book_number">
          <div class="chart"></div>
          <div class="chart"></div>
          <h6>
            booking number :
            <span>#{{ pills.booking_number }}</span>
          </h6>
        </div>
      </div>
      <!-- Head invoice -->

      <!-- client reservation data -->
      <dataInvoicePerson :pills="pills" :guest="pills?.rooms[0]?.guests[0]" />
      <!-- client reservation data -->

      <!-- welcome invoice data -->
      <div class="welcome_text">
        <!-- <h4>Dear Sir : {{ pills.client_name }}</h4> -->
        <h5>
          Greeting From <span>{{ pills.organization?.name }}</span>
        </h5>
        <div class="pragra">
          <p>
            First of All, We would like to take this opportunity to welcome you at
            <strong> {{ pills.organization?.name }} </strong> and tourism We are
            pleased to confirm the following reservation on a
            <span>{{ book_status_name }}.</span>
          </p>
        </div>
      </div>
      <!-- welcome invoice data -->

      <!-- Table reservation -->
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th class="frist">HOTEL</th>
            <th class="frist">ROOM TYPE</th>
            <th scope="col">MEAL</th>
            <th scope="col">PAX</th>
            <th scope="col">CHECK IN</th>
            <th scope="col">CHECK OUT</th>
            <th scope="col">NTS</th>
            <th scope="col">RATE</th>
            <th class="last">TOTAL</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(room, index) in pills.rooms" :key="index">
            <td>{{ pills.hotel_name }}</td>
            <td>
              <p class="mb-0 fs-8">
                {{ room.count }} X {{ room.title }}
              </p>
              <p class="mb-0 fs-8">{{ room.description }}</p>
            </td>
            <td>{{ room.meal_types[0]?.title }}</td>
            <td>
              <span>{{ room?.guests.length }}</span>
            </td>
            <td>
              <p class="mb-0">
                {{ pills.check_in }}
              </p>
              <span class="hijri">{{updateHijriDate(pills?.check_in ? new Date(pills?.check_in) : new Date())}}</span>
            </td>
            <td>
              <P class="mb-0">
                {{ pills.check_out }}
              </P>
              <span class="hijri">{{updateHijriDate(pills?.check_out ? new Date(pills?.check_out) : new Date())}}</span>
            </td>
            <td>{{ pills.numberOfNights }}</td>
            <td>
              {{
                (
                    pills.total_selling_price /
                    pills.numberOfNights
                ).toFixed(2)
              }}
            </td>
            <td class="last">
              {{ pills?.total_selling_price?.toFixed(2) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- Table reservation -->

      <!-- Table services -->
  <!--    <services :service="service" v-if="service.length" />-->
      <!-- Table services -->

      <!-- Terms & amount reservation -->
      <div class="amounts_terms">
        <!-- Terms -->
  <!--      <Terms :terms="pills?.Conditions" />-->
        <!-- Terms -->

        <TotalPrice
            :total_selling_price_with_tax="total_selling_price_with_tax"
            :selling_tax="selling_tax"
            :total_selling_price="total_selling_price"
        />
      </div>
      <!-- Terms & amount reservation -->

      <!-- Account Data -->
      <div class="table_account" v-if="pills?.account">
        <div
            class="list_account d-flex align-items-center justify-content-between w-100"
        >
          <div class="account_data">
            <p class="title_account">Account Name</p>
            <p class="text_account">: {{ pills.account?.title }}</p>
          </div>
          <div class="account_data">
            <p class="title_account">IBAN</p>
            <p class="text_account">: {{ pills.account?.iban }}</p>
          </div>
          <div class="account_data">
            <p class="title_account">Account number</p>
            <p class="text_account">: {{ pills.account?.account_number }}</p>
          </div>
          <div class="account_data">
            <p class="title_account">Swift Code</p>
            <p class="text_account">: {{ pills.account?.swift_code }}</p>
          </div>
        </div>
      </div>
      <!-- Account Data -->

      <!-- Invoice Organization Data -->
      <div class="invoice_organiztion_data">
        <ul class="social_accounts">
          <li>
            <i class="fa-solid fa-globe"></i>
            <a
                :href="pills.organization?.website_link"
                target="_blank"
                class="text_accounts"
            >{{ pills.organization?.website_link }}</a
            >
          </li>
          <li>
            <i class="fa-solid fa-envelope"></i>
            <a :href="`mailto:${pills.organization?.email}`" class="text_accounts">{{
                pills.organization?.email
              }}</a>
          </li>
          <li>
            <i class="fa-solid fa-phone"></i>
            <a :href="`tel:${pills.organization?.phone}`" class="text_accounts">{{
                pills.organization?.phone
              }}</a>
          </li>
          <li>
            <i class="fa-solid fa-location-dot"></i>
            <p class="text_accounts">{{ pills.organization?.address }}</p>
          </li>
        </ul>
        <div class="logo">
          <img :src="pills.organization?.default_image" />
          <employeeData :pills="pills" />
          <socialMedia :organization="pills?.organization" />
        </div>
      </div>
      <!-- Invoice Organization Data -->

      <div class="footer_invoice"></div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
// import services from "./component/servicesEN.vue";
import TotalPrice from "./component/TotalPriceEn.vue";
import socialMedia from './component/socialMedia.vue';
// import Terms from './component/termsEn.vue';
import dataInvoicePerson from './component/dataInvoicePersonEn.vue';
import employeeData from './component/employeeData.vue';

export default {
  name: "pill-hotel-cancel",
  components: {
    // services,
    TotalPrice,
    socialMedia,
    // Terms,
    dataInvoicePerson,
    employeeData
  },
  data() {
    return {
      pills: {
        rooms: [
          {
            guests: [
              {
                name: "",
                age: 0,
                nationality: "",
                phone: "",
                email: ""
              }
            ],
            meal_types: []
          }
        ],
        checkin: new Date(),
        checkout: new Date(),
        total_selling_price: null,
      },
      total_selling_price_with_tax: "",
      selling_tax: "",
      total_selling_price: "",
      bookId: null,
      book_status_name: "",
      type: this.$route.params.type,
      book_id: this.$route.params.id,
      optionDate: null,
      terms: "",
      visible: false,
      qr_code: ""
    };
  },
  methods: {
    fetchPayments() {
      axios
          .post("/get_invoice_book", {
            book_id: this.$route.params.id,
          })
          .then(({ data }) => {
            this.pills = data.data;
            // this.qr_code = data.data.qr_code;
            this.book_status_name = data.data.book_status_name;
            this.optionDate = data.data.client_option_date;
            this.total_selling_price =
                data.data.total_selling_price;
            this.selling_tax = data.data.selling_price_tax;
            this.total_selling_price_with_tax = data.data.total_selling_price_with_tax;
          });
    },

    //HijriDate
    updateHijriDate(Date) {
      console.log("DateTime", Date);
      if (Date == null) {
        return "";
      }
      const hijriFormatter = new Intl.DateTimeFormat(
          "ar-SA-u-ca-islamic-umalqura",
          {
            month: "long",
            day: "numeric",
          },
      );
      return hijriFormatter.format(Date).replace(/\s*هـ$/, "");
    },

    getHotelDataFromLocalStorage() {
      // Check if the data exists in localStorage
      if (localStorage.getItem("user")) {
        // Retrieve the data from localStorage
        this.userData = JSON.parse(localStorage.getItem("user"));
        // Use this.hotelData for further operations
        // console.log("Retrieved data:", this.userData);
      } else {
        // console.log('No data found in localStorage with the key "user"');
      }
    },
  },
  mounted() {
    this.getHotelDataFromLocalStorage(); // Call the method when the component is mounted
  },
  created() {
    this.fetchPayments();
  },
};
</script>

<style lang="scss" scoped>
@import "./_pill.scss";
.invoice {
  .invoice_organiztion_data {
    .social_accounts, .logo {
      width: 50% !important;
    }
  }
}
</style>
